
.preloader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}

.preloader-container .cssload-loader {
  position: absolute;
  width: 100px;
  height: 100px;
  left: 50%;
  top: 50%;
  margin-top: -50px;
  margin-left: -50px;
  perspective: 780px;
}

.preloader-container .cssload-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 50%;
}

.preloader-container .cssload-inner.cssload-one {
  left: 0;
  top: 0;
  animation: cssload-rotate-one 1.15s linear infinite;
  border-bottom-width: 6px;
  border-bottom-style: solid;
}

.preloader-container .cssload-inner.cssload-two {
  right: 0;
  top: 0;
  animation: cssload-rotate-two 1.15s linear infinite;
  border-right-width: 6px;
  border-right-style: solid;
}

.preloader-container .cssload-inner.cssload-three {
  right: 0;
  bottom: 0;
  animation: cssload-rotate-three 1.15s linear infinite;
  border-top-width: 6px;
  border-top-style: solid;
}

@keyframes cssload-rotate-one {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0);
  }

  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes cssload-rotate-two {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0);
  }

  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes cssload-rotate-three {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0);
  }

  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}


.ant-table-wrapper {
  overflow: auto;
}

.gx-card-p-title {
  .ant-card-head-title {
    padding: 0 24px;
  }
}

.gx-grid-thumb-cover img, .gx-thumb-cover-img {
  width: 100%;
  max-width: 100%;
  height: auto;
  min-height: auto;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
}

.gx-grid-thumb-cover a {
  display: block;
  width: 100%;
  height: 100%;
}
.amcharts-main-div a {
  display: none!important;
}
.gx-card .ant-card-head-title {
  padding-left: 15px!important;
}
.ant-card.ant-card-bordered.gx-card-widget {
  overflow: auto;
}
.full-layout-signup{
  overflow: auto!important;
}
