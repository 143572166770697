
@media screen and (max-width: 768px)
{
  .MuiDrawer-paper {
    max-width: 90%;
  }

  .gx-profile-banner {
    .gx-follower-list {
      &>li {
        width: 50%;
        padding-bottom: 10px;
      }
    }
  }

  .gx-layout-sider-header {
    .gx-site-logo {
      max-width: 100px;
      text-align: center;
      margin: 0 auto;
    }
  }
}
